.companypage-offer {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 25px;
    row-gap: 25px;

    margin-bottom: 25px;

    color: var(--theme-text-dark);
}

ul {
    margin-top: 0.1em;
    border-top: 1px #eee solid;
    padding: 0px 30px;
}

.companypage-offer p, .companypage-offer h3, .companypage-offer h4, .companypage-offer h5 {
    text-align: center;
    margin: 0.5em 0;
}

.companypage-offer li {
    padding: 0.3em 0;
}

.additional-offers {
    display: flex;
    align-content: space-between;
}

.additional-offers div {
    flex: 1 1 0;
}   

.additional-offers div:first-child {
    margin-right: 1em;
}

.companypage-fair {
    position: relative;
    overflow: hidden;
}

.companypage-map-background {
    position: absolute;
    z-index: -1;
    height: 200%;
    width: 200%;
    top: -50%;
    left: -50%;

    background-size: auto 100%;
    background-position: center;

    transform: translateX(175px);
}

.companypage-map-background-fade {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;

    background: rgb(255,255,255);
    background: linear-gradient(
        90deg, 
        rgba(255,255,255,0.6) 0%, 
        rgba(255,255,255,0.6) 40%, 
        rgba(255,255,255,0) 55%
    );
}

.companypage-sales-members {
    display: grid;
    column-gap: 15px;
    row-gap: 25px;
}

@media screen and (max-width: 1000px) {
    .companypage-sales-members {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media screen and (max-width: 700px) {
    .companypage-offer {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 15px;
        row-gap: 15px;
    }

    .companypage-see-map-box {
        height: 125px;
    }
    .companypage-map-background {
        transform: translateY(275px) translateX(-50px);
    }
    .companypage-map-background-fade {
        background: linear-gradient(
            180deg, 
            rgba(255,255,255,0.6) 0%, 
            rgba(255,255,255,0.6) 60%, 
            rgba(255,255,255,0) 75%
        );
    }

    .additional-offers {
        display: 'flex';
        flex-direction: column;
    }

    .additional-offers div {
        flex: 0 1 auto;
    }   

    .additional-offers div:first-child {
        margin-right: 0;
        margin-bottom:1em;
    }
    
}

@media screen and (max-width: 500px) {
    .companypage-offer {
        column-gap: 0;
        grid-template-columns: repeat(1, 1fr);
    }
    .companypage-sales-members {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}