.circle-icon {
    width: 85px;
    height: 85px;
    padding: 15px;
    border-radius: 100px;
    margin: auto;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border: 4px solid var(--theme-light-color-light);
}

.circle-icon img {
   max-width: 100%; 
   max-height: 100%;
}