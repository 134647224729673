.company-card-container.active {
    border: 5px solid var(--theme-primary-color-normal);
}

.company-card-container h2 {
    margin: 0;
    word-break: break-all;
}

.company-card {
    height: 80px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    display: flex;
    align-items: center;    
    box-shadow: 0px -5px 10px 2px var(--theme-dark-color-light);
}

.company-card.active {
    height: 70px;
    padding: 15px;
}


.company-card.disabled {
    opacity: 0.2;
}


.company-card.eyevinn {
    background-color: black;
}

.company-card.eyevinn.active {
    height: 70px;
    padding: 15px;
}


.company-card.eyevinn.disabled {
    opacity: 0.2;
}

.company-card img {
    max-height: 100%;
    max-width: 60%;
    margin: auto;
}

.company-card-employments {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0 5px;
}

.company-card-website {
    flex: 1;
}
