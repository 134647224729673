.language-select {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self:flex-end;
    padding: 8px;
    
    cursor: pointer;
    transition: all 0.25s;
}
.language-select:hover {
    transform: scale(1.1);
}

.language-select-globe {
    width: 23px;
}
