.navbar {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 66px;
    z-index: 1;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    box-shadow: 0px -5px 20px 5px var(--theme-dark-color-normal);
    color: var(--theme-text-bright);
    background-color: var(--theme-dark-color-normal);
}

.navbar-padding {
    box-sizing: border-box;
    padding: 12px;
    padding-left: 0px;
}

.navbar-leading {
    height: 100%;
}

.navbar-content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar-trailing {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.navbar-mbd-logo {
    padding-left: 18px;
    width: auto;
    height: 100%;
}

.side-logo {
    height: 100%;

   
}

.navbar-trailing-item {
    margin-left: 14px
}

.navbar-link {
    padding-right: 18px;

    font-weight: bold;
    transition: color 0.5s;
    cursor: pointer;
}

.navbar-link.active,
.navbar-link:hover {
    color: var(--theme-primary-color-normal);
}
.side-logo:hover {
    cursor: pointer;
}

.navbar-mobile-menu-button {
    display: none;
}


@media (max-width: 850px) {
    .side-logo {
        display: none;
    }
    .navbar-mobile-menu-button {
        display: block;
    }

    .navbar-content {
        position: fixed;
        top: 66px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;

        flex-direction: column;
        justify-content: space-around;

        background-color: var(--theme-dark-color-dark);

        opacity: 0;
        visibility: hidden;
        transform: translateX(25%);
        transition: transform 0.5s, opacity 0.5s, visibility 0.5s;
    }

    .navbar-link {
        font-size: 20px;
    }

    .navbar-content.active {
        transform: translateX(0%);
        opacity: 1;
        visibility: visible;
    }
}