.homepage {
    background-color: var(--theme-dark-color-normal);
}

.homepage-intro-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
}

.read-more-section {
    display: flex;
    justify-content: space-between;
}

.read-more {
    display: flex;
    flex-direction: column;
    flex: 1;

}

.homepage-instagram-section {
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 25px;
    row-gap: 25px;
}

.homepage-social-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 300px;
    margin: auto;
}

.photographer-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.9em;
    padding-top: 0.2em;
}

.photographer-info img {
    height: 1em;
    padding-right: 0.2em;
}

.photographer-info a {
    border-bottom: 2px solid var(--theme-primary-color-normal);
}

.digital-button {
    max-width: 320px;
}

.signature-cont {
    display: flex;
    width: 100%;
}

.signature-cont img {
    max-width: 200px;
}

@media screen and (max-width: 700px) {

    .homepage-instagram-section {
        grid-template-columns: repeat(2, auto);
        column-gap: 15px;
        row-gap: 15px;
    }
    
    .read-more-section {
        flex-direction: column;
    }

    .read-more:nth-of-type(1) {
        padding-bottom: 50px;
    }

    .read-more h1 {
        word-wrap: break-word;
        font-size: 24px;
    }

    .digital-button {
        max-width: 250px;
    }

    .signature-cont {
        justify-content: space-around;
        margin-top: 15px;
    }

    .signature-cont img {
        max-width: 130px;
    }
}