.content-section {
    max-width: 1000px;
    width: 100%;

    padding: 50px 50px;
    margin: auto;
    box-sizing: border-box;
}

.content-section-small {
    max-width: 600px;
}

.content-section-large {
    max-width: 1500px;
}

.content-section-background-light {
    color: var(--theme-text-dark);
    background-color: var(--theme-light-color-normal);
}

.content-section-background-dark {
    color: var(--theme-text-bright);
    background-color: var(--theme-dark-color-normal);
}

@media screen and (max-width: 700px) {
    .content-section {
        padding: 50px 20px;
    }
}