.hamburger-button {
    position: relative;
    height: 30px;
    width: 40px;

    cursor: pointer;
}

.hamburger-button div {
    position: absolute;
    height: 5px;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;

    transition: all 0.5s ease;
    opacity: 1;
}
.hamburger-button div:nth-child(1) {
    top: 0;
}
.hamburger-button div:nth-child(2),
.hamburger-button div:nth-child(3) {
    top: 50%;
    transform: translateY(-50%);
}

.hamburger-button div:nth-child(4) {
    bottom: 0;
}

.hamburger-button.active div {
    background-color: #ffd521;
}
.hamburger-button.active div:nth-child(1) {
    top: 50%;
    transform: scale(0);
    opacity: 0;
}
.hamburger-button.active div:nth-child(4) {
    bottom: 50%;
    transform: scale(0);
    opacity: 0;
}
.hamburger-button.active div:nth-child(2) {
    transform: rotate(-45deg);
}
.hamburger-button.active div:nth-child(3) {
    transform: rotate(45deg);
}
