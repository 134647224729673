:root {
    --shared-navbar-height: 66px;
}

.no-select {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.no-tap-highlight {
    -webkit-tap-highlight-color: transparent;
}

.nowrap {
    white-space: nowrap;
}

.flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}