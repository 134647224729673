.intro-screen-title {
    padding: 35px 35px 0px 35px;
    padding-bottom: 50px;
    box-sizing: border-box;
    width: 100%;

    text-transform: uppercase;
    font-weight: 900;
    font-size: 40px;
    text-align: center;
    color: #ffd521;

    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.0));
}

.intro-screen-title.bottom-padding {
    padding: 35px;
}

.intro-screen-title.no-gradient {
    background-image: none;
}

@media screen and (max-width: 700px) {
    .intro-screen-title {
        font-size: 35px;
    }
}

@media screen and (max-width: 400px) {
    .intro-screen-title {
        font-size: 30px;
    }
}

@media screen and (max-width: 350px) {
    .intro-screen-title {
        font-size: 25px;
    }
}