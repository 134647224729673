.intro-screen {
    position: relative;
    /*
    min-height: -we kit-fill-available;
    min-height: calc(100vh - var(--shared-navbar-height));
    */

    display: flex;
    justify-content: stretch;
    flex-direction: column;
    align-items: stretch;

    text-align: center;
    background-position: bottom;
    background-size: cover;
    background-attachment: scroll;
    
    overflow: hidden;
}

.intro-screen-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 1em;
}

.intro-screen-scroll-button {
    margin: auto;
    margin-bottom: 35px;
    margin-top: 10px;
}

.intro-screen-video {
    display: flex;
    width: 100vw;
    position: absolute;
    z-index: -1;
    background-color: var(--theme-dark-color-normal);
}

.intro-screen-video video {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    opacity: 0.5;
}