.profile-card {
    width: 100%;
}

.profile-card-image {
    position: relative;

    max-width: 100%;
    height: 325px;

    padding: 0 0;

    background-size: cover;
    background-position: top;
    
    text-align: center;
    overflow: hidden;
}

.profile-card-info-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    border-top: 2px solid var(--theme-primary-color-normal);

    background-color: rgba(55, 55, 55, 0.8);

    text-align: left;
    color: white;
}

.profile-card-info {
    padding: 15px 20px;
    box-sizing: border-box;
    float: left;
    white-space: pre-wrap;
}

.profile-card-name {
    font-size: 16px;
    font-weight: bold;
}

.profile-card-role {
    font-size: 15px;
}

.profile-info-link-container {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.profile-info-link {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;

    height: 100%;
    padding: 0 10px;

    cursor: pointer;
}
.profile-info-link img {
    width: 20px;
}
.linkedin-link {
    background-color: rgba(40, 103, 178, 0.75);
}
.linkedin-link:hover {
    background-color: rgba(50, 133, 208, 1);
}
.mail-link {
    background-color: rgba(255,213,33, 0.5);
}
.mail-link:hover {
    background-color: rgba(255,213,33, 0.75);
}
.mail-link img {
    width: 17px;
}