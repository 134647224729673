.text-with-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.text-with-content-text {
    flex: 1;
}

.text-with-content-content {
    min-width: 325px;
    margin-left: 50px;
}

.text-with-content.reverse {
    flex-direction: row-reverse;
    text-align: right;
}

.text-with-content.reverse .text-with-content-content {
    margin-left: 0;
    margin-right: 50px;
}


@media screen and (max-width: 700px) {

    .text-with-content {
        flex-direction: column !important;
        text-align: initial !important;
    }

    .text-with-content-content {
        margin-left: 0;
        margin-top: 30px;
        width: 100%;
    }

    .text-with-content.reverse .text-with-content-content {
        margin-right: 0;
    }
}