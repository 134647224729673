.company-logo-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    column-gap: 50px;
    row-gap: 25px;
}

.company-logo-item {
    cursor: pointer;
    text-align: center;
}

.company-logo-item img{
    height: auto;
    max-height: 60px;
    max-width: 100%;
}

@media screen and (max-width: 700px) {
    .company-logo-list {
        grid-template-columns: repeat(3, auto);
        column-gap: 25px;
        row-gap: 25px;
    }
}

