.contactpage-background {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background-attachment: scroll;
    background-size: cover;
    background-position: top;
}

.contactpage-pg-members {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}

.photographer-thanks {
    padding-top: 2em;
    text-align: center;
}

.photographer-thanks a {
    font-weight: bold;
    border-bottom: 2px solid var(--theme-primary-color-normal);
}

@media screen and (max-width: 900px) {
    .contactpage-background {
        background-position: 0% 90%;
        animation: paning-background-animation 30s cubic-bezier(0.2, 0, 0.8, 1)
            infinite;
    }
    .contactpage-pg-members {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 600px) {
    .contactpage-pg-members {
        grid-template-columns: repeat(1, 1fr);
    }
}

.intro-screen-title {
    padding: 35px 35px 0px 35px;
    padding-bottom: 50px;
    box-sizing: border-box;
    width: 100%;

    text-transform: uppercase;
    font-weight: 900;
    font-size: 40px;
    text-align: center;
    color: #ffd521;

    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.0));
}

/* Animation */
@keyframes paning-background-animation {
    0% {
        background-position: 0% 100%;
    }
    50% {
        background-position: 90% 100%;
    }
}
@keyframes paning-background-animation {
    50% {
        background-position: 90% 100%;
    }
    100% {
        background-position: 0% 100%;
    }
}
