.btn {
    display: flex;
    align-items: center;

    padding: 10px 20px;
    border-radius: 10px;

    text-align: center;
    font-weight: bold;
    
    background-color: var(--theme-primary-color-normal);
    color: #222;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);

    user-select: none;
    overflow: hidden;

    transition: all 0.2s ease 0s;
    cursor: pointer;
}

.btn.btn--white {
    background-color: var(--theme-light-color-light);
}

.btn.btn--rectangular{
    border-radius: 5px;
    width: 100px;
    padding: 17px;
}

.btn:hover {
    transition: all 0.2s ease 0s;
    color: #222;
    background-color: var(--theme-primary-color-light);
    box-shadow: 0px 8px 20px rgba(255, 229, 49, 0.4);
    transform: translateY(-3px);
}
.btn:active {
    transition: all 0.1s ease 0s;
    transform: translateY(0px);
    box-shadow: 0px 4px 15px rgba(255, 229, 49, 0.6);
}

.btn--compact, .btn--inline--icon {
    display: inline-block;
}

.btn--icon {
    width: 25px;
    height: 25px;
    padding: 17px;
    border-radius: 50%;
    text-align: center;
    background-color: aliceblue;
}
.btn--icon--big {
    width: 40px;
    height: 40px;
    padding: 20px;
}
.btn--icon img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    user-select: none;
}

.btn-image {
    height: 100%;
    width: 15px;
    float: right;

    opacity: 0.6;
}
.btn-text {
    flex: 1;
}

.btn--inline--icon img {
    max-width: 1.5em;
    height: 1.5em;
    user-select: none;
    margin-right: 1em;
}

.btn--inline--icon .inline-cont {
    display: flex;
    align-items: center;
    padding: 0.5em;
}

.btn--inline--icon .inline-cont span {
    white-space: nowrap;
}

@media screen and (max-width: 700px) {

    .btn--inline--icon .inline-cont span {
        white-space: normal;
    }
    
}