.studentpage-companies-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
}

.studentpage-active-company {
    margin-bottom: 25px;
}

.studentpage-active-company-right {
    display: flex;
    float: right;
    flex-direction: column;
    padding-left: 10px;
    width: 250px;
}

.studentpage-active-company-logo {
    background-size: contain;
    background-repeat: no-repeat;
    width: inherit;
    height: 75px;
}

.studentpage-active-company-employments, .studentpage-employments {
    display: flex;
    flex-wrap: wrap;
    padding-top: 5px;
}

.studentpage-employments {
    padding-bottom: 10px;
}

.studentpage-employments .filter {
    font-weight: bold;
    padding: 4px 5px;
    font-size: 14px;
    margin: 5px 0 5px 0;
}

.employments-clear {
    display: flex;
    flex-grow: 1;
    align-items: center;
}

.employments-clear img {
    height: 18px;
    cursor: pointer;
}

.studentpage-company-description {
    position: relative;
    overflow: hidden;
    transition: height 0.5s;
}

.studentpage-company-description-overflow {
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: linear-gradient(
        var(--theme-light-color-normal-transparent),
        var(--theme-light-color-normal)
    );
    position: absolute;
}

.studentpage-show-more-button {
    margin-right: 10px;
}

.studentpage-active-company-actions {
    margin-top: 15px;
    display: flex;
}

@media screen and (max-width: 700px) {
    .studentpage-companies-container {
        grid-template-columns: 1fr;
    }

    .studentpage-active-company-actions {
        flex-direction: column;
    }

    .studentpage-active-company-website-button {
        margin: 0;
        margin-top: 15px;
    }

    .studentpage-employments .filter { 
        width: 100%
    }
}