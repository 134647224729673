.text-section {
    white-space: pre-line;
    line-height: 1.5;
    font-weight: normal;
}

.text-section-left {
    text-align: left;
}

.text-section-center {
    text-align: center;
}

.text-section-right {
    text-align: right;
}

.text-section h1 {
    margin-top: 0
}