.card {
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 3px 10px rgba(0,0,0,0.3);
}
.card--light {
    box-shadow: 0 0px 8px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.1);
}
.card--unselected {
    box-shadow: none;
}
.card--unselected:hover {
    box-shadow: none;
}
.card--clickable {
    cursor: pointer;
    transition: box-shadow 0.25s, transform 0.25s;
}
.card--clickable:hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.26), 0 5px 10px rgba(0,0,0,0.43);
    transform: translateY(-5px);
}
.card--clickable.card--light:hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.1), 0 5px 10px rgba(0,0,0,0.2);
}
.card--gold {
    background: linear-gradient(338deg, rgba(255,215,0,1) 0%, rgb(241, 210, 29) 63%, rgba(255,255,255,1) 120%);
}

.card--silver {
    background: linear-gradient(338deg, #bdbded 0%, #ededed 63%, rgba(255,255,255,1) 120%);
}
.card--bronze {
    background: linear-gradient(338deg, #ee7411 0%, #ffa069 63%, rgb(255, 245, 234) 120%);
}